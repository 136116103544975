@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.home-container {
  display: flex;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.home-content {
  flex: 1;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}



.Login-page {
  height: 100vh;
  overflow: hidden;
}

.charts>div {
  width: max-content;
  /* padding: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  /* margin: 1rem; */
  height: max-content;
  background-color: #E7E7DB;
}

.tableheadtitle {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  font-size: 20px;
  color: #3B362A;
}

.Appointment-selection-container {
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.Appointment-picker>div>p {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  font-size: 20px;
  color: #3B362A;
}

.Today-appointment {
  width: 100%;
  height: 18rem;
  padding: 1rem;
  font-family: "Libre Baskerville", serif;
  background-color: #FBF1E7;
  color: #75623F;
  flex-direction: column;
  background-image: url('../public/icons/todayappointmentbg.png');
  background-size: 100%;
  object-fit: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  border-radius: 8px;
  display: flex;
  font-weight: 400;
}

.Today-appointment-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Today-appointment-title img {
  width: 4rem;
}

.Appointment-count {
  align-self: center;
  background: var(--Foundation-Ulva-Lactuca-Green-ulva-lactuca-green-200, #CAF6CA);
  width: 5rem;
  height: 5rem;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  font-size: 20px;
  color: #3B362A;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin: auto;
  justify-self: center;
}

.current-schedule {
  padding: 1rem;
  flex: 1.5;
  border-radius: 5px;
  background-color: #EFEFE7;
}

.current-schedule>p {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  color: #DA6E0B;
}

.current-schedule>div {
  display: flex;
  padding: 1rem;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  flex: 1.5;
  justify-content: space-between;
}

.current-schedule>div {}

.Appointment-picker {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Login-page nav {
  background-color: #F4ECD6;
  padding: 10px;
  display: flex;
  align-items: center;
  font-family: "Libre Baskerville", serif;
  color: #7c7870;
  font-weight: 400;
  justify-content: center;
}

.Login-content-items {
  background-color: #F4ECD6;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  font-family: "Libre Baskerville", serif;
  color: #7c7870;
  font-weight: 400;
  justify-content: center;
}

.login-text-inputs {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  width: 20rem;
  gap: 10px;
}

.login-otp-text-input {
  width: 30rem;
  display: flex;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700;
  font-size: 20px;
  margin: 1rem;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-otp-text-input div {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;
  align-items: center;
}

.login-otp-text-input>div>p:nth-child(1) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-size: 14px;
}

.login-otp-text-input>div>p:nth-child(2) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.login-action-buttons {
  display: flex;
  flex-direction: column;
  width: 15rem;
  margin: 1rem;
  gap: 15px;
}

.input-container input {
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.input-container img {
  position: absolute;
  width: 20px;
  left: 10px;
  color: #aaa;
}

.input-container input:focus {
  outline: none;
}

.input-container input {
  width: 100%;
  padding: 10px 10px 10px 40px;
  /* Adjust the padding to make space for the icon */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.input-container input::placeholder {
  color: #999;
}


.Login-content-items>p:nth-child(1) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  color: #75623F;
  margin: 1rem;
  ;
  font-size: 20px;
}


.home-content nav {
  background-color: #F4ECD6;

  align-items: center;
  font-family: "Libre Baskerville", serif;
  color: #7c7870;
  font-weight: 400;

}

.home-content-nav {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: sticky;
  justify-content: space-between;
  z-index: 20;
  top: 0;
  width: 100%;
  box-sizing: border-box;
}

.home-content-nav>div:nth-child(1) {
  justify-self: flex-start !important;
}

.Upcomingappointments {
  padding: 10px;
  width: 100%;
  height: 100vh;
  background-color: #E7E7DB;
  border-radius: 10px;
}

.Upcomingappointments>p {
  color: #75623F;
  text-align: center;
  font-family: "Libre Baskerville", serif;
  font-weight: 700;
}

.newappointmentcard {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}

.grid-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}


.gcontent {
  background-color: #F4ECD6;
  width: 10rem;
  /* height: 8rem; */
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  /* padding: 1rem; */
}

.gcontent>p:nth-child(1) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  text-align: center;

  color: #EFEFE7;
  border-radius: 5px 5px 0 0;
  width: 100%;
  padding: 10px;
  background-color: #75623F;
  font-size: 12px;
}

.Apppointment-insights-page {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.Apppointment-insights-page>div {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  display: flex;
  /* margin-top: 1rem; */
  /* width: 400px; */
  /* margin: 1rem; */
  color: #75623F;
}

.select-container {
  position: relative;
  width: 120px;
}

.styled-select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  transition: border-color 0.3s;
}

.styled-select:focus {
  outline: none;

}

/* Add an arrow icon */
.select-container::after {
  content: '▼';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #888;
  font-size: 14px;
}

.Apppointment-insights-page>div>select {
  font-size: 14px;
}

.gcontent>p:nth-child(2) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  font-size: 30px;
  padding: 10px;
  color: #3B362A;
}

#sidebar {
  background-color: #F4ECD6;
}

.menuitem img {
  width: 20px;
}

.menuitem {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}

.appointmentinsightstitle {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #75623F;
}

.appointmentinsightstitle>p {
  font-family: "Libre Baskerville", serif;
  font-weight: 700;

  color: #75623F;
}

.Appointment-insights-container {
  display: flex;
  gap: 1rem;
  /* justify-content: center; */
  /* justify-content: space-between; */
  align-items: center;
}

.TableRowContainer {
  background-color: #FDFDFD;
  border: 1px solid silver;
}

.Patient_Profile {
  padding: 1rem;
  margin-bottom: 5rem;
}

.Patient_Profile>nav {
  border-radius: 8px;
  justify-content: start;
  gap: 1rem;
}

.Patient_Profile_list_container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: row; */
  width: 100%;
  /* gap: 1rem; */
  /* margin: 1rem; */
}

.Patient-card {
  padding: 1rem;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #EFEFE7;
}

.Patient-avatar p {
  font-size: 3.5rem;
}

.Patient-avatar {
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  background-color: #D4CEC3;
  color: #75623F;
  font-size: 5rem;
  text-transform: uppercase;
  border-radius: 8px;
}

.Patient-avatar img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  border-radius: 8px;
}

.list-appointments {
  margin: 1rem;
}

.VikritiChart,
.PrakritiChart {
  width: max-content;
  background-color: #F8F8F4;
  height: 35rem;
  padding: 1rem 2rem;
  margin-top: 1rem;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  font-size: 14px;
}

.pinput-c {
  width: 15rem;
  margin: auto;
  margin-top: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.Patient-info {
  width: 20rem;
}

.Patient-profile-details-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* place-items: center; */
  grid-template-rows: repeat(3, 1fr);
  /* grid-gap:1rem; */
}

.Patient-profile-details-info div {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  margin: 5px;
  margin: 1rem;
}

.Patient-profile-details-info div img {
  width: 2rem;
}

.Patient-info-details>div {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  margin: 5px;
  margin: 1rem;
}

.Patient-info-details div img {
  width: 2rem;
}

.patient_name {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  text-align: center;
  margin: 10px;
}

.AppointmentTable {
  border-radius: 8px !important;
}

.Patient-appointment-details-title {
  border-bottom: 1px solid silver;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  font-size: 20px;
  padding: 1rem;

  width: 100%;
}

.Patient-wellnessCategories p {
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  font-size: 20px;
}

.Patient-wellnessCategories div div p {
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}

.wellness-img {
  width: 5rem;
  padding: 10px;
  background-color: #FBF1E7;
  border-radius: 8px;
}

.patient-action-item-buttons {
  width: 60%;
  bottom: 0;
  position: fixed;
  z-index: 1000;
  display: flex;
  padding: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: white;
}

.patient-action-item-buttons div {
  width: 10rem;
}


.appointment-id-section {
  gap: 10px;
  padding: 1rem;
}

.Patient-appointment-details {
  border-radius: 8px;
  /* display: flex; */
  justify-self: start;
  width: 100%;
  /* align-self: center; */
  /* flex:1; */
  /* flex-direction: column; */
  background-color: #EFEFE7;
}

.Patient-appointment-detail>div {
  flex: 0.2;
}

.patient-appointment-details-id {
  gap: 10px;
  align-items: center;
}

.patient-appointment-details-id>p:nth-child(odd) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  font-size: 14px;
}

.patient-appointment-details-id>p:nth-child(even) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-size: 12px;
}

.Patient-more-info {
  width: 100%;
  margin-top: 0;
  flex: 1;
  margin-left: 0;
}

.call-disconnected img {
  width: 20rem;
  align-self: center;
}

.Quickatbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
  background-color: #EFEFE7;
}

.Quickatbtn div {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  gap: 5px;
}

.Quickatbtn div img {
  width: 3rem;
}

.Reports-main-page {
  margin: 1rem;
}

.Reports-text-box {
  color: #75623F;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  font-size: 20px;
}

.No-reports {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.No-reports>p:nth-child(2) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.No-reports div {
  margin-top: 1rem;
}

.No-reports>p:nth-child(3) {
  font-family: 'Poppins', sans-serif !important;
  margin-top: 5px;
  font-weight: 400;
}

#dialog {
  background-color: #EFEFE7;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  padding: 1rem;
}

.btn-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px
}

.btn-content img {
  width: 2rem;
}

.Upload-Reports {
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.dialog-action-items {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-around;
}

.dialog-action-items div {
  width: 15rem;
}

.No-reports img {
  width: 20rem;
}

.Select-Reports {
  display: flex;
  width: 100%;
  margin: 1rem;
  gap: 1rem;
  flex-direction: column;
}

.ChooseFiles {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  background-color: #F1EFEC;
  border-radius: 8px;
  border: 2px dashed #C0B7A7;
  justify-content: center;
  padding: 1rem;
  height: 20rem;
}

.Select-Reports select {
  background-color: #ECECE2;
  padding: 1rem;
  color: #3B362A;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  padding-right: 1rem;
  border-radius: 8px;
}

#outlined-basic {
  background-color: #F1EFEC;
  outline: 1px solid #F1EFEC;
  color: #625E55;
}

.MuiInputLabel-root {
  color: #625E55 !important;
}

.SelectedReportCard {
  background-color: #F1EFEC;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 12rem;
  height: 10rem;
}

.ListReportCard {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  flex-direction: row-reverse;
  background-color: #F8F8F4;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  padding: 1rem;
  font-size: 12px;
  cursor: pointer;
}

.di-selectedReportscards {
  margin: 1rem;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1rem;
}

.ListReportCard div p:nth-child(1) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  font-size: 14px;
}

.ListReports {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin: 1rem;
}

.ListReportCard div img {
  width: 3rem;
}

.SelectedReports {
  display: grid;
  /* place-items: center; */
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  height: 25rem;
  overflow-y: scroll;
  margin-bottom: 2rem;
  grid-gap: 1rem;
}

.SelectedReportCard p {
  width: 10rem;
  height: 20px;
  line-break: strict;
  overflow: hidden;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
  font-weight: 400;
}

.SelectedReportCard img {
  width: 6rem;
}

#outline-basic:focus {
  outline: none;
}

.camera-container div {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  justify-content: center;
}

.Select-Reports>p:nth-child(1) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  font-size: 20px;
}

.ChooseFiles p {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  color: #DA6E0B;
}

.ChooseFiles img {
  width: 15rem;
}

.TakePicturebtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Selected-Reports-item {
  width: 60rem;
}

.display-report {
  background-color: #625E55;
  margin-top: 1rem;
  display: flex;
  height: 30rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 1rem;
}

.uploadReportbtn {

  margin-bottom: 1rem;
  width: 20rem;
  text-align: center;
}

.pBtnsection {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 1rem;
  box-sizing: border-box;
  background-color: white;
  padding: 0.5rem;
}

.g {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1075%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='url(%23SvgjsLinearGradient1076)'%3e%3c/rect%3e%3cpath d='M 0%2c22 C 57.6%2c59.4 172.8%2c199.8 288%2c209 C 403.2%2c218.2 460.8%2c55.6 576%2c68 C 691.2%2c80.4 748.8%2c275.4 864%2c271 C 979.2%2c266.6 1036.8%2c52.6 1152%2c46 C 1267.2%2c39.4 1382.4%2c199.6 1440%2c238L1440 560L0 560z' fill='%23184a7e'%3e%3c/path%3e%3cpath d='M 0%2c311 C 57.6%2c349.2 172.8%2c481.2 288%2c502 C 403.2%2c522.8 460.8%2c426.6 576%2c415 C 691.2%2c403.4 748.8%2c460.8 864%2c444 C 979.2%2c427.2 1036.8%2c326.2 1152%2c331 C 1267.2%2c335.8 1382.4%2c440.6 1440%2c468L1440 560L0 560z' fill='%232264ab'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1075'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='15.28%25' y1='-39.29%25' x2='84.72%25' y2='139.29%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1076'%3e%3cstop stop-color='%230e2a47' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(16%2c 101%2c 211%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

/* .containes {
  display: flex;
  background-image: #FFFFFF;
  height: 78vh;
 
} */


.main {
  display: grid;
  place-items: center;
  padding: 1rem;
  overflow: hidden;
  height: 100%;
  background-color: #fdfdfd
}

.containes {
  display: flex;
  /* background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
  height: 85vh;
  width: 90vw;
  background-color: #F1EFEC;
  /* overflow-y: hidden; */
  border-radius: 13px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

}

.chat-section {
  display: flex;
  flex-direction: column;
  border: 1px solid red;
  flex: 0.35;
}

.chat-box {
  display: flex;
  height: 100%;
  width: 100%;
  transition: 2s ease-out;
  justify-content: space-between;
  flex-direction: column;
  /* background-color: #FDFCF9; */
  flex: 1;
}

.chat-body {
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  padding: 30px;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: auto;
  outline: none;
}

.chat-search {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f6f6f6; */
  height: 39px;
  padding: 1.2rem;
  /* padding: 1rem 2rem 1rem 2rem; */
}

.chat-search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 35px;
  border-radius: 20px;
}

.chat-search-box>.MuiSvgIcon-root {
  color: gray;
  padding: 10px;
  font-size: 40px;
}

.chat-search-box>input {
  border: none;
  outline-width: 0;
  margin-left: 10px;
}

.chat-section-header {
  padding: 20px;
  /* background-image: linear-gradient(60deg, #29323c 0%, #485563 100%); */
  border-right: 1px solid lightgray;
  display: flex;

  justify-content: space-between;
  border-radius: 13px 0px 0px 0px;
}

.w {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  flex: 1;
  color: #3B362A;
  border-radius: 0px 13px 13px 0px;
  background-color: #F1EFEC;
}



.Display {
  display: flex;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  background-color: #E7E7DB;
  border-radius: 13px 0 13px 13px;
  flex-direction: column;
  flex: 0.4;
}

.logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 15vw;
}

.logo>img {
  width: 6vw;
}

.Contact-list {
  display: flex;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid #f6f6f6;
}

.Contact-info {
  display: flex;
  flex-direction: row;
  padding: 1.2rem;
  justify-content: space-between;
}

.Contact-info>h4 {
  margin-top: 5px;
  font-family: "Libre Baskerville", serif;
  font-size: 20px;
  font-weight: 700;
  color: #3B362A;
  margin-left: 5px;
}

.Contact-info>span {
  cursor: pointer;
}

.Contact-section {
  height: 100%;
  /* Fill 100% of the parent container's height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  padding: 1rem;
  /* Add padding */
  box-sizing: border-box;
  /* Include padding in the element's total height */
}


.Contact-section::-webkit-scrollbar {
  width: 6px;
  /* For vertical scrollbar */
  height: 6px;
  /* For horizontal scrollbar */
}

.Contact-section::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Light background color for the track */
}

.Contact-section::-webkit-scrollbar-thumb {
  background: #625E55;
  /* Gray color for the thumb */
  border-radius: 10px;
  /* Rounded corners for the thumb */
}

.Contact-section::-webkit-scrollbar-thumb:hover {
  background: #75623F;
  /* Darker color for thumb on hover */
}

.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  top: 100px;
}

.User-info>h2 {
  font-size: 16px;

}

.User-info {
  margin-left: 15px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}

.chat-head {
  background-color: #E7E7DB;
  border-radius: 0 10px 0 0;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700;
  padding: 10px;
}

.typing-text {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-size: 14px;
  color: green;
}

.typinganimation-container {
  width: 5rem;
  background-color: hsl(0, 0%, 99%);
  position: absolute;
  border-radius: 1rem 1rem 1rem 0;
  bottom: 10px;
}

.Goals-section-container>p:nth-child(1) {
  font-family: "Libre Baskerville", serif;
  color: #75623F;
  font-weight: 700;
  font-size: 16px;
  margin-top: 1rem;
}

.serverity-symptoms-div {
  padding: 1rem;
  background-color: #FDFDFD;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.chat-body :focus {
  outline: none;
}

.chat-footer-action-inputs {
  display: flex;
  flex: 1;
  gap: 2rem;
  justify-content: space-evenly;
  align-items: center;
}

.message {
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
  color: black;
  padding: 0.7rem;
  border-radius: 1rem 1rem 1rem 0;
  max-width: 28rem;
  width: fit-content;
  display: flex;
  word-wrap: break-word;
  flex-direction: column;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  gap: 0.5rem;
}

.message>span:nth-child(2) {
  align-self: end
}

.own {
  align-self: flex-end;

  /* color: white; */
  border-radius: 1rem 1rem 0 1rem;
  background: #FFFFFF;
  border: 1px solid #C6B897;
  color: #3B362A;
  /* background: linear-gradient(98.63deg, #24e4f0 0%, #358ff9 100%); */
}


.chat-body::-webkit-scrollbar {
  display: none;
}

.chat-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-top: 1px solid lightgray;
  /* border:1px solid red; */
}

.chat-footer-textarea {
  width: 100%;
  margin-top: 10px;
}

.chat-footer-textarea>textArea {

  border-radius: 8px;
  background-color: #EFEFE7;
  width: 100%;
  padding: 5px;
  border: none;
  overflow-y: auto;
  resize: none;
}

textarea:focus {
  outline: none;
}

.SendIcon {
  width: 3rem;
  height: 3rem;
  display: flex;
  padding: 5px;
  align-items: center;
  color: white;
  justify-content: center;
  border-radius: 50%;
  background-color: #DA6E0B;
}

.AddIcon {
  width: 3rem;
  height: 3rem;
  display: flex;
  border-radius: 5px;
  padding: 5px;
  align-items: center;
  border: 1px solid #DA6E0B;
  justify-content: center;
}

/* .chat-box {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid red;
  flex-direction: column;
  transition: 2s ease-out;
} */

.AudioRecorder {
  /* display: none; */
}

.chat-footer>textArea::-webkit-scrollbar {
  display: none;
}

.audio-transcript-container {
  width: 100%;
  /* max-width: 600px; */
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}







.transcript-textarea {
  width: 100%;
  height: 120px;
  padding: 15px;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  border: 1px solid #ccc;
  border-radius: 10px;
  line-height: 1.6;
  background-color: #fff;
  resize: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.transcript-textarea:focus {
  border-color: #ff9800;
  box-shadow: 0 0 5px rgba(255, 152, 0, 0.5);
  outline: none;
}


.UploadPrescription-page {
  margin: 1rem;
}

.Prescription-sections {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.datectn {
  background-color: #F4F4EE;
  border-radius: 8px;
  padding: 1rem;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}

.Pdt {
  padding: 1rem;
  border-radius: 8px;
  background: rgba(212, 206, 195, 0.4);
}

.Prescription-sh-h {
  font-family: "Libre Baskerville", serif;
  font-size: 16px;
  font-weight: 500;
  color: rgba(117, 98, 63, 1);
}

.Pdt div>p:nth-child(odd) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.Pdt div>p:nth-child(even) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}

.Medical-details {
  display: grid;
  margin: 1rem;
  place-items: center;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  grid-gap: 1rem;
}

.add-more {
  padding: 1rem;
  border-radius: 8px;
  cursor: pointer;
  width: 10rem;
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  color: #FDFDFD;
  gap: 5px;
  justify-content: center;
  background-color: #C0B7A7;
}

.herbal-products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  grid-gap: 2rem 1rem;
  margin-top: 1rem;
}

.PrakritiAssesmentEdit {
  padding: 1rem;
}

.PrakritiAssesmentEdit-container {
  display: flex;
  /* justify-content: space-between; */
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}

.PrakritiAssesmentEdit-container>div:nth-child(1) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  flex: 0.50;
}

.viewreportsectionimgcontainer {
  max-width: 25rem;
  height: fit-content;
  object-fit: cover;
}

.PrakritiAssesmentEdit-container>div:nth-child(2) {
  flex: 0.50;
}

.answer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 5px;
}

.selected {
  background-color: #e0ffe0;
}

.answer-content {
  display: flex;
  align-items: center;
}

.answer-image {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.answer-actions {
  display: flex;
  align-items: center;
}

.vpk-indicator {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.edit-answer-button {
  color: #1976d2;
  margin-left: 10px;
  cursor: pointer;
}

.herbal-product-card img {
  width: 10rem;
}

.FoodtobeTakencard {
  border: 1px solid silver;
  padding: 10px;

}

.herbal-product-card {
  width: 10rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  color: #3B362A;
  padding: 1rem;
  border: 1px solid silver;
  position: relative;
  /* Ensure relative positioning for the overlay */
}

.herbal-product-card p {
  width: 10rem;
  height: 3rem;
  font-size: 14px;
  text-align: start;
  overflow: hidden;
}

.img-container {
  position: relative;
}

.img-container img {
  width: 100%;
}

.overlay-checkbox {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 10px;
  right: 10px;
  z-index: 1;
  background-color: white;
  border-radius: 50%;
}

.Herbal-products-container {
  width: 100%;
  height: 40rem;

  padding: 1rem;
  /* margin: 1rem; */
}

.medicine-input .MuiOutlinedInput-root {
  position: relative;
  background-color: #F1EFEC;
}

.AISuggestionimg img {
  width: 2.5rem;

}

.AISuggestionimg {
  display: flex;
  align-items: center;
  color: #75623F;
  font-family: "Libre Baskerville", serif;
  gap: 1rem;
}

.header {
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
  background-color: #ECECE2;
  flex-direction: row;
  margin-bottom: 0px;
}

.datectn {
  background-color: #F4F4EE;
  border-radius: 8px;
  padding: 1rem;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}

.header>img {
  width: 10rem;
}

.Pdt {
  padding: 1rem;
  border-radius: 8px;
  background: rgba(212, 206, 195, 0.4);
}

.prescription-doctor-details>p:nth-child(1) {
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  font-size: 20px;
}

.prescription-doctor-details>p {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
}

.prescription-doctor-details {
  padding: 10px;
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.patient-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 1rem !important;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 8px;
  gap: 20px;
  margin-right: 1rem;
  background: rgba(212, 206, 195, 0.4);
  margin: 1rem 0;
}

.patient-info>div {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.labtestlist {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  padding: 1.5rem;
}

.prescription-container {
  width: 100%;
  background-color: #F8F8F4;
  box-sizing: border-box;
  overflow-x: hidden;
}

.Prescription-sh-h {
  font-family: "Libre Baskerville", serif;
  font-size: 22px;
  margin: 1rem;
  margin-left: 20px;
  margin-top: 2rem;
  font-weight: 500;
  color: rgba(117, 98, 63, 1);
}

.container {
  display: flex;
  justify-content: space-between;
  /* margin: 1rem; */
  padding: 1rem;
  gap: 1rem;
}

.card {
  border-radius: 8px;
  width: 100%;
  background-color: #F8F8F4;
}

.card-header {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  background-color: #ECECE2;
  padding: 5px;
  border-radius: 8px 8px 0 0;
}

.card-header .icon {
  font-size: 14px;
  padding-left: 1rem;
}

.card-content {
  display: flex;
  background-color: #F8F8F4;
  align-items: center;
  flex-wrap: wrap;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  border-radius: 0px 0px 8px 8px;
  gap: 1rem;
  padding: 1rem;
}

.card-content>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-content img {
  border-radius: 50%;
  width: 70px;
  height: 70px;
}


.lifestyle-container {
  display: flex;
  gap: 5px;
  width: 100%;
}

.lifestyle-card {
  background-color: #E7E7DB;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 30%;
  margin: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}

.lifestyle-card-header {
  font-family: 'Libre Baskerville', serif;
  font-size: 20px;
  font-weight: 500;
  color: #6d4c41;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  text-align: center;
  background-color: rgba(253, 253, 253, 0.4);
  padding: 5px;
}

.lifestyle-card-content {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  color: #4e342e;
  padding-left: 1rem;
}

.lifestyle-card-content ul {
  padding: 0;
}

.lifestyle-card-content li {
  margin-bottom: 0.5rem;
}

.LifeStyle-changes-Container {
  width: 90%;
  margin: 1rem;
}

.LifeStyle-changes-Container ul {
  background-color: #F8F8F4;
  padding: 1rem;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 3rem;
  list-style-position: inside;
  margin: 1rem;
  color: #4e342e;
  font-weight: 500;
}

.Patient-details {
  width: 100%;
  /* max-width: 760px; */
  margin: auto;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  grid-template-rows: auto;
  font-size: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #F8F8F4;
}

.Medical-details-div {
  width: 100%;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  grid-template-rows: auto;
  font-size: 16px;
  box-sizing: border-box;
  border-radius: 8px;
}

.Supplement-medicine-card-container {
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.Supplement-medicine-card-info {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.Supplement-medicine-card-info>p:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
}

.Supplement-medicine-card {
  padding: 1rem;
  background-color: #EFEFE7;
  cursor: pointer;

  border-radius: 5px;
}

.Supplement-medicine-card-info>div {
  margin-top: 1rem;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.Supplement-medicine-card-info>div span {
  color: #DA6E0B;
}

.Supplement-medicine-card-image img {
  width: 10rem;
  mix-blend-mode: multiply;
  object-fit: contain;
}

.Patient-details div,
.Medical-details-div div {
  display: flex;
  align-items: center;
  gap:10px;
}

.Medical-details-div div>p:nth-child(odd) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  color: #3B362A;
}

.prescription-buy-now {
  width: 20rem;
}

.Medical-details-div div>p:nth-child(even) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  color: #3B362A;
}

.Patient-details div>p:nth-child(odd) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  color: #3B362A;
}

.Patient-details div>p:nth-child(even) {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  color: #3B362A;
}


.item-container {
  width: 90%;
  display: grid;
  background-color: #F8F8F4;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 1rem;
  padding: 1rem;
  margin: 1rem;
  border-radius: 8px;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.item img {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-right: 1rem;
}

.medicine-card {
  display: flex;
  align-items: center;
  gap: 5px;
}

.medicine-card img {
  width: 4rem;
}

.page-break {
  page-break-before: always;
  margin-bottom: 2rem;
}

.after-page-break {
  padding-top: 1rem;
  margin-top: 2rem;
}

.item p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #4e342e;
  font-weight: 500;
  margin: 0;
}

.Signature {
  font-family: 'Libre Baskerville', serif;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 2rem;
  margin-top: 5rem;
  color: "#3B362A";
  text-align: center;
}

a {
  text-decoration: none;
}

.no-decoration {
  text-decoration: none;
  color: inherit;
}

.Signature img {
  width: 10rem;
}

.AIHTMltext {
  font-family: 'Poppins', sans-serif;
  /* font-weight: 500 !important; */
}

.medicine-input .MuiInputAdornment-root {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 1;
}

.medicine-input .MuiInputAdornment-root .MuiIconButton-root {
  background-color: white;
  border-radius: 50%;
}

.Search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  flex-direction: column;
}

.search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px silver solid;
  background-color: #EBEBEA;
  width: 100%;
  padding: 10px;

  /* height: 45px; */
  border-radius: 8px;
}



.search-box img {
  width: 2rem;
}

.search-box>input {
  border: none;
  width: 100%;
  outline-width: 0;
  background-color: #EBEBEA;
  padding: 3px;
}

.search-box>input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}


.ListofCategories {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;

  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 0;

}

.ListofCategories>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  width: 5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
  cursor: pointer;
  font-size: 16px;
  gap: "1rem"
}

.ListofCategories>div>img {
  width: 100%;
  object-fit: cover;
}

.ListofCategories>div>p {
  margin-top: 10px;
  font-size: 10px;
}

.dietrc {
  width: 100%;

}

.dietrc p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
  color: #625E55;
}

.D_dietc {
  background-color: #F8F8F4;
  border-radius: 8px;
  padding: 10px;
}

.category {
  margin-bottom: 5px;
}

.category h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
  text-align: left;
  color: #3B362A;
  margin-left: 10px;
}

.item-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.food-item {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  color: #3B362A;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

.food-image {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.food-item-img-container {
  background-color: #EFEFE7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 8rem;
  height: 8rem;
}

.food-item p {
  margin: 0;
  font-size: 14px;
  text-align: center;
}

.practices-card {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;

}

.practices-card select {
  background-color: #ECECE2;
  padding: 10px;
  color: #3B362A;
  font-family: 'Poppins', sans-serif !important;
  border-radius: 8px;
}

.practices-card p {
  margin-left: 5px;
  color: #75623F;
  margin-bottom: 5px;
}

.practices-card ul {
  font-size: 12px;
  margin-left: 20px;
  margin-top: 5px;
  list-style: circle;
}

.lifestylechanges {
  background-color: #ECECE2;
  padding: 1rem;

  border-radius: 8px;
}

.lifestylechanges:focus {
  outline: none;
}

input[type="date"] {
  background-color: #ECECE2;
  font-family: 'Poppins', sans-serif !important;
  padding: 5px;
  border-radius: 8px;
  font-weight: 400;
}

.food-item input {
  margin-top: 10px;
}

.FoodtobeTaken {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  margin: 5px;
}

.formbtn {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.checkbox {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
}

.checkbox>input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circleIndicatorContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inner-circle-content {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  background: radial-gradient(circle, #FF1F00, #FF7A00, transparent, transparent);
  border-radius: 50%;
  /* Make it circular */
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-circle-text {
  color: white;
  /* Text color */
  font-size: 12px;
  /* Adjust text size as needed */
  text-align: center;
}

.Prakriti-result-section {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
}

.Prakriti-result-item {

  height: 100%;
  width: 20rem;
  display: flex;
  /* border: 1px solid red; */
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.ass-lables {
  padding: 10px;
  border-radius: 5px;
  background: #FDFDFD;

}

.Prakriti-result-item>p {
  color: #3B362A;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  font-size: 20px;
}

.PhenotypeChar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.prakritilabel {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-size: 10px;
  margin: 5px;
}

.dominant-prakriti-doshas {
  padding: 5px;
}

.dominant-prakriti-doshas>p:nth-child(1) {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 25px;
  font-style: normal;
}

.dominant-prakriti-doshas>p:nth-child(2) {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 18px;
  font-style: normal;
}

.dominant-prakriti-doshas>p:nth-child(3) {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 30px;
  font-style: normal;
}

.Tele-consultation-book-main-container {
  display: flex;
  /* margin-top: 3rem; */
}

.Tele-consultation-book-main-container>div {
  flex: 0.50;
  padding: 1rem;
}

.Tele-consultation-doctor-details>div:nth-child(1) {
  padding: 1rem 1rem 0px 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #F2E9D2;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  /* background-image: url('../src/Compontents/Assets/images/doctor_profile_bg.png'); */
}

.Tele-consultation-doctor-details>div:nth-child(1) img {
  width: 12rem;
  margin-top: auto;
  align-self: center;
}


.Tele-consultation-doctor-details>div:nth-child(3)>h2 {
  color: #3B362A;
  font-family: "Libre Baskerville", serif;
  font-weight: 700;
  font-size: 20px;
}

.Tele-consultation-doctor-details>div:nth-child(3)>h2>span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.Tele-consultation-doctor-details>div:nth-child(3)>div>p:nth-child(1) {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;

}

.Tele-consultation-doctor-details>div:nth-child(3)>div>p:nth-child(2) {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.doc-info-card>div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.icon-text>img {
  width: 20px !important;

}

.Reviews-container {
  height: 10rem;
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  /* border:1px solid red; */
}

.doc-info-card {
  display: flex;
  color: #3B362A;
  justify-content: space-evenly;
  padding: 5px;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 14px;
  background: linear-gradient(90.215deg, rgba(246, 246, 246, 0%) 0%, rgba(235, 227, 209, 76%) 9%, rgba(232, 221, 197, 68%) 57%, rgba(239, 234, 222, 49%) 95%, rgba(246, 246, 246, 0%) 100%);
}

.Prakriti-r-c-item {
  width: 100%;

  padding: 1rem;
}

.Prakriti-r-c-item>p {
  color: #DA6E0B;
  font-size: 16px;
  cursor: pointer;
  /* width: max-content; */
  text-align: center;
  border-bottom: 1px solid #DA6E0B;
}


DayPicker-NavButton {
  display: none !important;
}

.DatePicker-container {
  display: flex;
  gap: 10px;
}

.Slot-errreponse {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #3B362A;
  padding: 10px;
  width: 100%;
  background-color: #F1EFEC;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.Slot-errreponse img {
  width: 5rem;
}

.Tele-consultation-doctor-slots {
  width: 100%;
}

.DatePicker-container>div {
  flex: 1;
}

.Patientlistinput input {
  flex: 1;
  padding: 8px;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #EBEBEA;
}

.Patientlistinput input:focus {
  outline: none;
}

.PatientlistTitletext {
  font-family: "Libre Baskerville", serif;
  font-weight: 700;
  font-size: 20px;
  color: #3B362A;
}

.slotTitle>p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.Slot-container {
  display: grid;
  flex: 1;
  width: 100%;
  margin-top: 1rem;
  grid-gap: 5px 10px;
  height: fit-content;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 40px;
  /* This will allow the rows to adjust based on their content */
}


.Slot-container>div {
  padding: 5px;
  border-radius: 5px;
  width: 8rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #C6B897;
  cursor: pointer;
  border: 1px solid #C6B897;
}

.booked {
  background-color: #D6D6D6 !important;
  /* Example of a different style for booked slots */
  color: #A5A39D !important;
}

.group-container {
  border: 1px solid #dee2e6;
  font-family: "Poppins", sans-serif;
  /* font-weight: 400; */
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 15px;
}

.group-item {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f1f3f5;
}

.selected {
  background-color: #efefe7;
  color: black !important;
  border: none !important;
}